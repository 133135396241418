<template>
  <div>
    <v-img :src="require('../assets/waveform.svg')" class="my-3 bg-img" cover>
      <v-container fill-height>
        <v-row fill-height justify="center">
          <v-col align-self="center">
            <h1 class="display-3">Triggered</h1>
            <h1 class="subtitle-1 pl-6 mt-4">Audio Integration</h1>
            <v-btn href="#whatwedo" color="primary" class="mt-10">
              What we do
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-img>

    <section id="whatwedo" style="scroll-margin-top: 64px">
      <v-container class="my-12">
        <v-row>
          <v-col cols="12" md="6" align="center">
            <v-img width="80%" :src="require('../assets/calendar.svg')"></v-img>
          </v-col>
          <v-col>
            <p>
              Triggered AI is the audio integration system designed to help you
              schedule audio playback at defined times. Intuitively create
              trigger events using the inbuilt calendar, manually trigger audio
              playback at the push of a button and easily connect the system
              into your existing audio infrastructure.
            </p>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <v-container class="my-md-12">
      <v-row>
        <v-col cols="12" md="6" align="center">
          <p>Create audio profiles from the mp3 files you upload.</p>
          <v-img
            max-height="150px"
            contain
            :src="require('../assets/musicnote.svg')"
          ></v-img>
        </v-col>
        <v-col cols="12" md="6" align="center">
          <p>Play only a selected segment or the whole track.</p>
          <v-img :src="require('../assets/soundwaveform.svg')"></v-img>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="mt-12">
      <v-row>
        <v-col>
          <h1>Make an enquiry</h1>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="mb-12">
      <v-row>
        <v-col cols="12" md="6">
          <p>
            Triggered AI systems are currently on back order. To register your
            interest and organise a demonstration, please contact us.
          </p>
        </v-col>
        <v-col cols="12" md="6">
          <v-form v-model="valid">
            <v-container>
              <v-row>
                <v-col>
                  <iframe
                    width="100%"
                    style="min-height: 750px"
                    src="https://script.google.com/macros/s/AKfycbxx8Y-wBKZVYIKdb6C1C4nZQxN2Fof0kxdmaWnLQRX071iKxd0Z6B0RQvjFVcidhyUx/exec"
                    frameborder="0"
                  ></iframe>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="my-12">
      <v-row><v-col></v-col></v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  data: () => ({
    valid: false,
    firstname: "",
    lastname: "",
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => v.length <= 20 || "Name must be less than 20 characters",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
    phone: "",
    phoneRules: [(v) => !isNaN(v) || "Numbers only"],
  }),
  components: {},
};
</script>

<style>
html {
  scroll-behavior: smooth;
}
.bg-img .v-image__image {
  opacity: 0.3 !important;
}
p {
  font-size: 18px;
}
</style>

<template>
  <v-app>
    <v-app-bar app flat class="app-bar">
      <div class="d-flex align-center">Triggered AI</div>

      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>

<style scoped>
.v-application {
  font-family: "Montserrat", sans-serif;
  background-color: #141218 !important;
}
.app-bar {
  background-color: #141218 !important;
}
</style>
